<template>
  <component :is="bannerData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="bannerData === undefined">
      <h4 class="alert-heading">Error fetching banner data</h4>
      <div class="alert-body">
        No banner found with this banner id. Check
        <b-link class="alert-link" :to="{ name: 'banner-list' }">
          Banner List
        </b-link>
        for other banners.
      </div>
    </b-alert>

    <b-tabs v-if="bannerData" class="tabs-primary" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Banner</span>
        </template>
        <banners-tab-information
          :banner-data="bannerData"
          :banner-category-options="bannerCategoryOptions"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import bannerStoreModule from "../bannerStoreModule";
import BannersTabInformation from "./BannersTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    BannersTabInformation,
  },
  setup() {
    const bannerData = ref(null);
    const PRODUCT_CATEGORY_APP_STORE_MODULE_NAME = "app-banner";

    const banner = {
      id: 0,
      banner_category_id: null,
      alt: null,
      has_day_range: 0,
      start_date: null,
      end_date: null,
      is_active: 1,
      image_path: null,
    };

    // Register module
    if (!store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
      store.registerModule(
        PRODUCT_CATEGORY_APP_STORE_MODULE_NAME,
        bannerStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "banner-create") {
        bannerData.value = banner;
      } else {
        store
          .dispatch("app-banner/fetchBanner", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            console.log("get banner categpry response", response);
            bannerData.value = response.data.banner;
          })
          .catch((error) => {
            console.log("error when fetching banner", error);
            if (error.response.status === 404) {
              bannerData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const bannerCategoryOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          banner_categories: true,
        })
        .then((response) => {
          bannerCategoryOptions.value = response.data.banner_categories;
        });
    };

    return {
      bannerData,
      refetchData,
      refetchOption,
      bannerCategoryOptions,
    };
  },
};
</script>

<style></style>
