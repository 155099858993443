<template>
  <div id="user_form" v-if="bannerData">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ bannerData.id == 0 ? "Add" : "Edit" }} Banner
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">

            <!-- Banner Category -->
            <b-col cols="12" md="6">
              <b-form-group
                label="Banner Category"
                label-for="banner_category_id"
                label-cols-md="4"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="banner_category_id"
                  rules="required"
                >
                  <div class="form-col-select">
                    <v-select
                      :state="errors.length > 0 ? false : null"
                      id="banner_category_id"
                      background-color="white"
                      v-model="bannerData.banner_category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="bannerCategoryOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Select banner categories"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            
            <b-col cols="12">
              <b-form-group label-for="is_active">
                <validation-provider
                  #default="{ errors }"
                  name="is_active"
                  rules="required"
                >
                  <b-form-checkbox
                    :checked="bannerData.is_active ? true : false"
                    @change="
                      bannerData.is_active = !bannerData.is_active
                    "
                    class="custom-control-success"
                    name="check-button"
                    switch
                    inline
                  />
                  {{
                    bannerData.is_active
                      ? "Active"
                      : "Active"
                  }}
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label-for="has_day_range">
                <validation-provider
                  #default="{ errors }"
                  name="has_day_range"
                  rules="required"
                >
                  <b-form-checkbox
                    :checked="bannerData.has_day_range ? true : false"
                    @change="
                      bannerData.has_day_range = !bannerData.has_day_range
                    "
                    class="custom-control-success"
                    name="check-button"
                    switch
                    inline
                  />
                  {{
                    bannerData.has_day_range
                      ? "Promotion Banner"
                      : "Promotion Banner"
                  }}
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Start Date -->
            <b-col cols="12" md="6" v-if="bannerData.has_day_range">
              <validation-provider
                #default="validationContext"
                name="Promotion Start Date"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols="12"
                  class="required"
                  label="Promotion Start Date"
                  label-for="start_date"
                >
                  <flat-pickr
                    v-model="bannerData.start_date"
                    class="form-control"
                    :config="futureDateConfig"
                    placeholder="YYYY-MM-DD"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: End Date -->
            <b-col cols="12" md="6" v-if="bannerData.has_day_range">
              <validation-provider
                #default="validationContext"
                name="Promotion End Date"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols="12"
                  class="required"
                  label="Promotion End Date"
                  label-for="start_date"
                >
                  <flat-pickr
                    v-model="bannerData.end_date"
                    class="form-control"
                    :config="futureDateConfig"
                    placeholder="YYYY-MM-DD"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Field: Banner Image -->
          <b-row class="m-0 w-100">
            <b-col
              cols="12"
              class="d-flex align-item-center justify-content-center"
            >
              <el-upload
                class="avatar-uploader"
                list-type="picture-card"
                ref="upload_image"
                :action="uploadUrl"
                :headers="headerObj"
                name="image_path"
                multiple
                :limit="1"
                :data="bannerData"
                :on-change="onChange"
                :on-remove="onChange"
                :auto-upload="false"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="mt-2"
              key="product_has_image"
              v-if="imageChanged === false && bannerData.id > 0 && bannerData.image_path !== null"
            >
              <el-image
                style="width: 100%; aspect-ratio: 2 /1; padding: 0"
                fit="cover"
                :src="`${uploadUrl}${bannerData.image_path}`"
                :preview-src-list="documentList"
              >
              </el-image>
            </b-col>
          </b-row>

          <!-- Button: Submit & Reset Button.-->

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  methods: {
    onChange(file, fileLists) {
      this.imageChanged = true;
      this.bannerData.image_path = file.raw;
    },
    hide() {
      this.$router.replace("/banner/list");
    },
    onSubmit() {
      console.log("submit data", this.bannerData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (this.bannerData.has_day_range === 0 || this.bannerData.has_day_range === false || this.bannerData.has_day_range === undefined) {
          this.bannerData.start_date = null;
          this.bannerData.end_date = null;
        }

        let formData = new FormData();

        formData.append("id", this.bannerData.id);
        formData.append("banner_category_id", this.bannerData.banner_category_id);
        formData.append("image_changed", this.imageChanged ? 1 :0);
        formData.append("is_active", this.bannerData.is_active ? 1 :0);
        formData.append("has_day_range", this.bannerData.has_day_range ? 1 :0);
        formData.append("start_date", this.bannerData.start_date);
        formData.append("end_date", this.bannerData.end_date);
        formData.append("image_path", this.bannerData.image_path);

        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-banner/addBanner", formData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/banner/list");
            })
            .catch((error) => {
              this.loading = false;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    bannerData: {
      type: Object,
      required: true,
    },
    bannerCategoryOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props.bannerData);
    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);
    const documentList = ref([]);
    documentList.value = [
      `${uploadUrl.value}${props.bannerData.image_path}`,
    ];

    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const loading = ref(false);
    const imageChanged = ref(false);

    const resetData = () => {
      emit("refetch-data");
    };
    const resetbannerData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetbannerData
    );

    onMounted(() => {});

    return {
      loading,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetbannerData,
      documentList,
      uploadUrl,
      headerObj,
      imageChanged
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
